import React from 'react';


const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null; // Don't render if modal is not open
   

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="w-full">
        {children}
      </div>
    </div>
  );
};

export default Modal;
