import "./App.css";
import Submit from "./pages/Submit";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Submit />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
